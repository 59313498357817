import LanguageObserver from 'language-observer';
import ResourceInjector from 'resource-injector';
import WheelFortune from 'wheel-fortune';
import TextSlicer from 'text-slicer';
import DialogLite from 'dialog-lite';

import GainMessage from './utils/GainMessage';
import './data/translations.js';

const App = {
  wheelFortune: null,
  textSlicer: null,
  dialogLite: null,
  languageObserver: null,
  gainMessage: new GainMessage(),

  initWheelFortune() {
    const firstSpin = () => {
      document.body.classList.add('first-spin-complete');

      this.gainMessage.slideToNext(0, 1).then();
    };

    const secondSpin = () => {
      document.body.classList.add('second-spin-complete');

      this.gainMessage.slideToNext(1, 2).then();

      setTimeout(() => {
        this.dialogLite.open({ stylingClass: 'dialog-lite--first' });
      }, 2250);
    };

    const spinStates = [
      { stopSegment: 3, callback: () => firstSpin() },
      { stopSegment: 6, callback: () => secondSpin() },
    ];

    this.wheelFortune = new WheelFortune({
      spinStates,
      rotationCount: 4,
      segmentCount: 6,
      wheelLibration: true,
      containerEl: '.wheel-fortune',
      segmentsEl: '.wheel-fortune__segments',
      buttonEl: '.wheel-fortune__button',
    });

    this.wheelFortune.init();
  },

  initDialogLite() {
    this.dialogLite = new DialogLite();
    this.dialogLite.init();
  },

  initLanguageObserver() {
    this.languageObserver = new LanguageObserver();
    this.languageObserver.init();
  },

  initTextSlicer() {
    document.querySelectorAll('.text-slicer').forEach((element) => {
      this.textSlicer = new TextSlicer({
        container: element,
        cssVariables: true,
        mode: 'chars',
      });

      this.textSlicer.init();
    });
  },

  async loadExternalResources() {
    const resourceInjector = new ResourceInjector();
    await resourceInjector.loadScript(
      'https://champcasino.ink/common/scripts/signup-form.js',
      { defer: true },
      5000,
    );
  },

  async init() {
    await this.loadExternalResources();
    this.initLanguageObserver();
    this.initWheelFortune();
    this.initDialogLite();
    this.initTextSlicer();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
