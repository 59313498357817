const locales = ['ru', 'kz', 'mx', 'tr', 'az'];

window.translations = window.translations || {};

const translationsWheel = {
  ru: {
    content: {
      title: {
        welcome: "Крути и выигрывай!",
        bonusOne: "1000 FS",
        bonusTwo: "1000 FS + 150 000₽",
      },
    },
  },
  kz: {
    content: {
      title: {
        welcome: "Айналдыр және жеңіп ал!",
        bonusOne: "1000 FS",
        bonusTwo: "1000 FS + 150 000₸",
      },
    },
  },
  mx: {
    content: {
      title: {
        welcome: "¡Gira y gana!",
        bonusOne: "1000 FS",
        bonusTwo: "1000 FS + 150 000 MXN",
      },
    },
  },
  tr: {
    content: {
      title: {
        welcome: "Çevir ve kazan!",
        bonusOne: "1000 FS",
        bonusTwo: "1000 FS + 150 000₺",
      },
    },
  },
  az: {
    content: {
      title: {
        welcome: "Fırlat və qazan!",
        bonusOne: "1000 FS",
        bonusTwo: "1000 FS + 150 000₼",
      },
    },
  },
};

locales.forEach(locale => {
  window.translations[locale] = {
    ...window.translations[locale],
    ...translationsWheel[locale],
  };
});
