class GainMessage {
  #gainMessages;

  constructor() {
    this.#gainMessages = [
      document.querySelector('.gain-message-one'),
      document.querySelector('.gain-message-two'),
      document.querySelector('.gain-message-three'),
    ];
  }

  delay(duration) {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }

  async slideToNext(fromIndex, toIndex) {
    this.#gainMessages[fromIndex].classList.remove('slide-out', 'slide-null');
    this.#gainMessages[fromIndex].classList.add('slide-in');

    await this.delay(300);

    this.#gainMessages[toIndex].classList.remove('slide-in', 'slide-null');
    this.#gainMessages[toIndex].classList.add('slide-out');
  }
}

export default GainMessage;
